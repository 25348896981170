<template>
  <div class="jd-goods-detail-wrap bgc-F7F7F7" ref="goodsDetail">
    <navbarTools v-show="isScroll" :leftArrow="true" :fixed="true"></navbarTools>
    <div class="icon-scroll-back" v-show="!isScroll">
      <div class="btn-icon-back flex" @click="goBackFn">
        <span class="icon iconfont iconfanhui ml-2"></span>
      </div>
    </div>
    <!-- 商品详情 -->
    <div class="jd-goods-detail-box">
      <!-- 商品图片轮播 -->

      <van-swipe indicator-color="#FE6507" :autoplay="3000">
        <van-swipe-item v-for="item in goodsInfo?.goodsThumbImageList" :key="item">
          <div class="fs0 line-height-0">
            <img class="w-100" :src="item" />
          </div>
        </van-swipe-item>
      </van-swipe>
      <div class="bgc-fff pl15 pt15 pr15 pb15 goods-msg">
        <div class="c-FE6507 fs14 line-height-14 flex text-left align-items-baseline">
          <div class="fs28 font-weight line-height-28">
            {{ goodsInfo?.coinPrice }}
          </div>
          <div>工钱币</div>
          <div class="ml10 c-999">
            京东价
            <del>¥{{ goodsInfo?.marketPrice }}</del>
          </div>
        </div>
        <div class="mt15 fs16 c-333 line-height-24">
          <span class="fs12 c-fff jd-tag vertical-m">京东自营</span>
          <span class="ml10 vertical-m">{{ goodsInfo?.goodsName }}</span>
        </div>
      </div>

      <!-- 地址+服务 -->
      <div class="bgc-fff mt10 radius6 pl15 pr15 line-height-55">
        <!-- 地址 -->
        <div class="flex justify-space-between fs14 c-333 nowrap" @click="addressFn">
          <div class="w-295">
            <div class="on-line" @click="cityFn">
              <span class="font-weight">送至</span>
              <span class="ml18 c-FE6507 icon iconfont iconweizhi mr6 fs14"></span>
              <span
                >{{ goodsInfo?.userDefaultAddress?.provinceName
                }}{{ goodsInfo?.userDefaultAddress?.cityName
                }}{{ goodsInfo?.userDefaultAddress?.areaName
                }}{{ goodsInfo?.userDefaultAddress?.townName }}</span
              >
            </div>
          </div>
          <div class="flex">
            <span class="c-FE6507 mr3"
              >{{ goodsInfo?.goodsStatusDesc }}
              <span class="icon iconfont iconiconfontjiantou2 c-333"></span>
            </span>
          </div>
        </div>
        <!-- 服务 -->
        <div class="jd-border-top flex text-left fs12 pt10 pb10 line-height-30 c-333">
          <div class="flex text-left mr10 nowrap">
            <img class="h-12 mr4" :src="OSS_URL + '/shop/check.png'" />
            <span>京东发货&售后</span>
          </div>
          <div class="flex text-left mr10 nowrap">
            <img class="h-12 mr4" :src="OSS_URL + '/shop/check.png'" />
            <span>满99元免运费</span>
          </div>
          <div class="flex text-left nowrap">
            <img class="h-12 mr4" :src="OSS_URL + '/shop/after-sale.png'" />
            <span>{{ goodsInfo?.is7NoReasonReturnDesc }}</span>
          </div>
        </div>
      </div>
      <!-- 商品详情 -->
      <div id="jd-goods-content" class="mt10">
        <div v-html="goodsInfo?.goodsIntroduction"></div>
      </div>

      <!-- 商品规格参数 -->
      <div class="pl15 pr15 mt10 bgc-fff pt5 pb5" id="jd-goods-guige">
        <div v-html="goodsInfo?.goodsParam"></div>
      </div>
      <div class="h-60 iphonex-bottom"></div>
      <div class="jd-btn-fixed-bottom iphonex-bottom">
        <div
          class="radius3 bgc-FC7301 line-height-40 fs16 c-fff text-center mt5"
          @click="buyFn"
          :class="{ 'btn-no-buy': goodsInfo?.goodsStatus != 0 }"
        >
          立即购买
        </div>
      </div>
    </div>

    <!-- 设置购买数量 -->
    <buy-number
      ref="buyNumber"
      :goodsId="goodsId"
      :goodsChannel="goodsInfo?.goodsChannel"
    ></buy-number>
    <!-- 城市选择 -->
    <jd-city ref="jdCity" @selectCity="selectCityFn"></jd-city>
  </div>
</template>
<script>
import buyNumber from "@/components/shop/buyNumber";
import jdCity from "@/components/shop/jdCity";
import {
  defineComponent,
  reactive,
  toRefs,
  getCurrentInstance,
  ref,
  onMounted,
  nextTick,
} from "vue";
import { jdGoodsDetail, jdGoodsSaleStatusByArea } from "@/api/shop";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import navbarTools from "@/components/navbarTools";
import { ua } from "@/utils/ua";
import { Toast } from "vant";
export default defineComponent({
  components: {
    buyNumber,
    navbarTools,
    jdCity,
  },
  created() {
    let app = getCurrentInstance();
    app.proxy.jdGoodsDetailFn();
  },
  mounted() {
    let app = getCurrentInstance();
    window.addEventListener("scroll", app.proxy.handleScroll);
  },
  setup(props) {
    const $store = useStore();
    const $route = useRoute();
    const $router = useRouter();
    const buyNumber = ref(null);
    const jdCity = ref(null);
    let app = getCurrentInstance();
    const state = reactive({
      OSS_URL: process.env.VUE_APP_OSS_URL,
      goodsInfo: null,
      isScroll: false,
      cityList: [],
      goodsId: $route.query.goodsId,
    });
    const handleScroll = () => {
      if (document.body.scrollTop || document.documentElement.scrollTop) {
        state.isScroll = true;
      } else {
        state.isScroll = false;
      }
    };
    const buyFn = () => {
      if (state.goodsInfo.goodsStatus == 0) {
        buyNumber.value.openFn(true);
      }
    };
    const cityFn = () => {
      jdCity.value.openFn(true);
    };
    const jdGoodsDetailFn = async () => {
      const loading = Toast.loading({
        message: "加载中...",
        forbidClick: true,
        overlay: true,
      });
      let rs = await jdGoodsDetail({ goodsId: state.goodsId });
      state.goodsInfo = rs.data;
      loading.clear();
      nextTick(() => {
        changeJdCss();
      });
    };
    const changeJdCss = () => {
      //图片详情样式
      let obj = document.getElementById("jd-goods-content");

      if (obj) {
        let jdDescItems = [];
        if (obj.getElementsByClassName("ssd-module-mobile-wrap").length) {
          jdDescItems = obj.getElementsByClassName("ssd-module-mobile-wrap");
        } else if (obj.getElementsByClassName("ssd-module-mobile").length) {
          jdDescItems = obj.getElementsByClassName("ssd-module-mobile");
        } else if (obj.getElementsByClassName("ssd-module-wrap").length) {
          jdDescItems = obj.getElementsByClassName("ssd-module-wrap");
        }
        if (jdDescItems.length == 1) {
          var jdDesc = jdDescItems[0];
          var container = jdDescItems[0].parentElement;
          if (container.offsetWidth < jdDesc.offsetWidth) {
            var targetWidth = Math.min(jdDesc.offsetWidth, container.offsetWidth);
            var scaleValue = targetWidth / jdDesc.offsetWidth;
            var fontSizeValue = Math.round(14 / scaleValue);
            jdDesc.style.transformOrigin = "0px 0px";
            jdDesc.style.transform = "scale(" + scaleValue + ")";
            jdDesc.style.fontSize = fontSizeValue + "px";
            jdDesc.style.lineHeight = "2";
            jdDesc.style.height = jdDesc.clientHeight * scaleValue + "px";
          }
        } else {
          let divs = obj.getElementsByTagName("div");
          let tables = obj.getElementsByTagName("table");
          if (divs.length) {
            for (let i = 0; i < divs.length; i++) {
              divs[i].style.width = obj.offsetWidth + "px";
            }
          }
          if (tables.length) {
            for (let j = 0; j < tables.length; j++) {
              tables[j].style.width = obj.offsetWidth + "px";
            }
          }
        }
      }
    };
    const jdGoodsSaleStatusByAreaFn = async () => {
      let rs = await jdGoodsSaleStatusByArea();
    };
    const goBackFn = () => {
      if (ua.isAndroidApp || ua.isIosApp) {
        app.proxy.$bridge.handleMethods("goBack");
      } else {
        $router.go(-1);
      }
    };
    const selectCityFn = async (options) => {
      state.goodsInfo.userDefaultAddress.provinceName = options[0].name;
      state.goodsInfo.userDefaultAddress.cityName = options[1].name;
      state.goodsInfo.userDefaultAddress.areaName = options[2].name;
      state.goodsInfo.userDefaultAddress.townName = options[3]?.name;
      let rs = await jdGoodsSaleStatusByArea({
        areaId: options[2].id,
        cityId: options[1].id,
        goodsId: state.goodsId,
        provinceId: options[0].id,
        townId: options[3]?.id ?? 0,
      });
      state.goodsInfo.goodsStatusDesc = rs.data.sellStatus == "0" ? "有货" : "缺货";
      state.goodsInfo.goodsStatus = rs.data.sellStatus;
    };
    return {
      buyNumber,
      jdCity,
      buyFn,
      cityFn,
      jdGoodsDetailFn,
      handleScroll,
      ...toRefs(state),
      goBackFn,
      selectCityFn,
    };
  },
});
</script>
<style lang="scss" scoped>
.jd-goods-detail-wrap {
  min-height: 100vh;
  padding-top: constant(safe-area-inset-top) !important; // iOs 11
  padding-top: env(safe-area-inset-top) !important; // iOs 11+ (feature)
  ::v-deep(.navbar) {
    width: 375px;
    .van-nav-bar {
      background: #fff;
      .van-nav-bar__left .van-icon {
        font-size: 24px;
        color: #000;
      }
    }
  }
  .banner {
    width: 343px;
    height: 118px;
    top: 56px;
    left: 15px;
    .van-swipe {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .goods-msg {
    border-radius: 0px 0px 6px 6px;
    .jd-tag-box {
      position: absolute;
      top: 0;
      left: 0;
    }
    .jd-tag {
      padding: 0 2px;
      height: 16px;
      line-height: 16px;
      background: #fe6507;
      border-radius: 2px;
    }
  }
  .btn-no-buy {
    color: #ffffff;
    background: rgba($color: #000000, $alpha: 0.24);
  }
  ::v-deep(#jd-goods-content) img {
    width: 100%;
    height: auto;
    vertical-align: bottom;
  }
  .bgc-FC7301 {
    background: #fc7301;
  }
}
</style>
